export default [
  require('./speciem.jpg'),
  require('./speciem2.jpg'),
  require('./speciem3.jpg'),
  require('./speciem4.jpg'),
  require('./speciem5.jpg'),
  require('./speciem6.jpg'),
  require('./speciem7.jpg'),
  require('./speciem8.jpg'),
  require('./speciem9.jpg'),
  require('./speciem10.jpg'),
  require('./speciem11.jpg'),
  require('./speciem12.jpg'),
  require('./speciem13.jpg'),
  require('./speciem14.jpg'),
  require('./speciem15.jpg'),
  require('./speciem16.jpg'),
  require('./speciem17.jpg'),
  require('./speciem18.jpg'),
  require('./speciem19.jpg'),
  require('./speciem20.jpg'),
  require('./speciem21.jpg'),
  require('./speciem22.jpg'),
  require('./speciem23.jpg'),
  require('./speciem24.jpg'),
  require('./speciem25.jpg'),
  require('./speciem26.jpg'),
  require('./speciem27.jpg'),
  require('./speciem28.jpg'),
  require('./speciem29.jpg'),
  require('./speciem30.jpg'),
  require('./speciem31.jpg'),
  require('./speciem32.jpg'),
  require('./speciem33.jpg'),
  require('./speciem34.jpg'),
  require('./speciem35.jpg'),
  require('./speciem36.jpg'),
]