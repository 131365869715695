export var lettersDefault = [
  { letter:"A", style:"default" },
  { letter:"B", style:"default" },
  { letter:"C", style:"default" },
  { letter:"D", style:"default" },
  { letter:"E", style:"default" },
  { letter:"F", style:"default" },
  { letter:"G", style:"default" },
  { letter:"H", style:"default" },
  { letter:"I", style:"default" },
  { letter:"J", style:"default" },
  { letter:"K", style:"default" },
  { letter:"L", style:"default" },
  { letter:"M", style:"default" },
  { letter:"N", style:"default" },
  { letter:"O", style:"default" },
  { letter:"P", style:"default" },
  { letter:"Q", style:"default" },
  { letter:"R", style:"default" },
  { letter:"S", style:"default" },
  { letter:"T", style:"default" },
  { letter:"U", style:"default" },
  { letter:"V", style:"default" },
  { letter:"W", style:"default" },
  { letter:"X", style:"default" },
  { letter:"Y", style:"default" },
  { letter:"Z", style:"default" },
  { letter:"a", style:"default" },
  { letter:"b", style:"default" },
  { letter:"c", style:"default" },
  { letter:"d", style:"default" },
  { letter:"e", style:"default" },
  { letter:"f", style:"default" },
  { letter:"g", style:"default" },
  { letter:"h", style:"default" },
  { letter:"j", style:"default" },
  { letter:"i", style:"default" },
  { letter:"k", style:"default" },
  { letter:"l", style:"default" },
  { letter:"m", style:"default" },
  { letter:"n", style:"default" },
  { letter:"o", style:"default" },
  { letter:"p", style:"default" },
  { letter:"q", style:"default" },
  { letter:"r", style:"default" },
  { letter:"s", style:"default" },
  { letter:"t", style:"default" },
  { letter:"u", style:"default" },
  { letter:"v", style:"default" },
  { letter:"w", style:"default" },
  { letter:"x", style:"default" },
  { letter:"y", style:"default" },
  { letter:"z", style:"default" },
]

export var lettersExtended = [
  { letter:"A", style:"default" },
  { letter:"A", style:"ss01" },
  { letter:"A", style:"ss02" },
  { letter:"B", style:"default" },
  { letter:"B", style:"ss01" },
  { letter:"B", style:"ss02" },
  { letter:"C", style:"default" },
  { letter:"C", style:"ss01" },
  { letter:"C", style:"ss02" },
  { letter:"D", style:"default" },
  { letter:"D", style:"ss01" },
  { letter:"D", style:"ss02" },
  { letter:"E", style:"default" },
  { letter:"F", style:"default" },
  { letter:"G", style:"default" },
  { letter:"G", style:"ss01" },
  { letter:"G", style:"ss02" },
  { letter:"H", style:"default" },
  { letter:"I", style:"default" },
  { letter:"J", style:"default" },
  { letter:"K", style:"default" },
  { letter:"L", style:"default" },
  { letter:"M", style:"default" },
  { letter:"M", style:"ss01" },
  { letter:"M", style:"ss02" },
  { letter:"N", style:"default" },
  { letter:"N", style:"ss01" },
  { letter:"N", style:"ss02" },
  { letter:"O", style:"default" },
  { letter:"P", style:"default" },
  { letter:"P", style:"ss01" },
  { letter:"P", style:"ss02" },
  { letter:"Q", style:"default" },
  { letter:"R", style:"default" },
  { letter:"S", style:"default" },
  { letter:"T", style:"default" },
  { letter:"U", style:"default" },
  { letter:"V", style:"default" },
  { letter:"W", style:"default" },
  { letter:"X", style:"default" },
  { letter:"Y", style:"default" },
  { letter:"Z", style:"default" },
  { letter:"a", style:"default" },
  { letter:"b", style:"default" },
  { letter:"c", style:"default" },
  { letter:"d", style:"default" },
  { letter:"e", style:"default" },
  { letter:"f", style:"default" },
  { letter:"g", style:"default" },
  { letter:"h", style:"default" },
  { letter:"j", style:"default" },
  { letter:"i", style:"default" },
  { letter:"k", style:"default" },
  { letter:"l", style:"default" },
  { letter:"m", style:"default" },
  { letter:"n", style:"default" },
  { letter:"o", style:"default" },
  { letter:"p", style:"default" },
  { letter:"q", style:"default" },
  { letter:"r", style:"default" },
  { letter:"s", style:"default" },
  { letter:"t", style:"default" },
  { letter:"u", style:"default" },
  { letter:"v", style:"default" },
  { letter:"w", style:"default" },
  { letter:"x", style:"default" },
  { letter:"y", style:"default" },
  { letter:"z", style:"default" },
]


